/* Убедитесь, что body и основный контейнер не имеют фона и границ */
body, html, #root {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: #20222C;
  color: #E9E9EA;
}

.App {
  text-align: center;
  border: none;
  background-color: inherit; /* Используйте inherit, чтобы унаследовать цвет фона от body */
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border: none;
}

.modal h2 {
  margin-top: 0;
}

.modal ul {
  list-style-type: none;
  padding: 0;
}

.modal li {
  margin-bottom: 10px;
}

.modal button {
  margin-top: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border: none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Убедитесь, что у MainCont нет границы или фона */
.MainCont {
  border: none;
  background: none;
}

.MiniCont {
  border: none;
  background: none;
}
